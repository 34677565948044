import { senderService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { SenderResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AddSender extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public sender!: SenderResource | null;

  public dialogVisible = true;

  public senderForm: Partial<SenderResource> & { area: Array<string> } = {
    senderName: '',
    senderNumber: '',
    remark: '',
    address: '',
    area: []
  };

  public senderFormRules = {
    senderName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('sender.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    senderNumber: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('sender.inputNumber')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    address: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('sender.inputAddress')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    area: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('sender.selectArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public created(): void {
    if (!this.sender) {
      this.title = 'sender.addSender';
      return;
    }
    this.title = 'sender.editSender';
    this.senderForm.area = [this.sender.province ?? '', this.sender.city ?? '', this.sender.district ?? ''];
    Object.assign(this.senderForm, this.sender);
  }

  public submit(): void {
    (this.$refs.senderForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        const [province, city, district] = this.senderForm.area;
        this.senderForm.province = province;
        this.senderForm.city = city;
        this.senderForm.district = district;
        if (!this.sender) {
          await senderService.post(this.senderForm);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await senderService.put(this.senderForm);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.senderForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
