import { senderService } from '@/api';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { SenderResource } from '@/resource/model';
import { dateFormat, messageError, showWarningConfirm, translation } from '@/utils';
import { Message, MessageBox } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import AddSender from './add-sender/add-sender.vue';
@Component({
  components: { AddSender }
})
export default class Sender extends mixins(PagingMixin) {
  public tableOptions: OsTableOption<SenderResource> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { prop: 'createTime', order: 'descending' }
  };

  public queryOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'system:sender:template:save',
      handleClick: this.openSenderDialog
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'system:sender:template:batchDelete',
      plain: true,
      disabled: true,
      handleClick: this.batchDeleteSender
    }
  ];

  public columnOptions: Array<OsTableColumn<SenderResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'senderName',
      label: 'sender.senderName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'senderNumber',
      label: 'sender.senderNumber',
      minWidth: '130px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'sender.address',
      minWidth: '200px',
      formatter: (rowData: Object): string => {
        return `${(rowData as SenderResource).province}
        ${(rowData as SenderResource).city}
        ${(rowData as SenderResource).district}
        ${(rowData as SenderResource).address}
        `;
      },
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return dateFormat((rowData as SenderResource).createTime);
      }
    }
  ];

  public rowOperationOptions: RowOperation<SenderResource> = {
    fixed: 'right',
    width: '200px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'system:sender:template:edit',
        handleClick: (rowData: SenderResource): void => {
          this.openSenderDialog(rowData);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'system:sender:template:delete',
        handleClick: (rowData: SenderResource): void => {
          this.deleteSender(rowData.id);
        }
      }
    ]
  };

  public queryForm = {
    keywords: ''
  };

  public sender: SenderResource | null = null;

  public senderDialogVisible = false;

  private selectedRows: Array<SenderResource> = [];

  public created(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.selectedRows = [];
    (this.$refs.senderTable as OsTable).clearSelection();
    this.loadData();
  }

  public loadData(): void {
    this.tableOptions.loading = true;
    senderService
      .getList(this.queryForm)
      .then(res => {
        this.tableOptions.data = res.data ?? [];
        this.totalData = res.total ?? 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOptions.loading = false;
      });
  }

  public handleSelectionChange(selectedData: Array<SenderResource>): void {
    this.selectedRows = selectedData;
  }

  public editSender(data: SenderResource): void {
    const findItem = this.tableOptions.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public closeAddSenderDialog(): void {
    this.senderDialogVisible = false;
    this.sender = null;
  }

  private openSenderDialog(sender?: SenderResource): void {
    this.sender = sender ?? null;
    this.senderDialogVisible = true;
  }

  private deleteSender(id: number): void {
    MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    })
      .then(async () => {
        try {
          this.tableOptions.loading = true;
          await senderService.delete(id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        } finally {
          this.tableOptions.loading = false;
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeleteSender(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          this.tableOptions.loading = true;
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await senderService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        } finally {
          this.tableOptions.loading = false;
          this.selectedRows = [];
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  @Watch('selectedRows')
  private handleSelectChanged(value: Array<SenderResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add' && x.operationType !== 'import') {
        x.disabled = value.length === 0;
      }
    });
  }
}
